export default defineNuxtRouteMiddleware(async (to, from) => {
    const configCookie = useCookie('transkribus-config');
    const configStore = useConfig()
    const { userProfile } = useKeycloak()
  
    if (!configCookie.value?.welcomeFlowCompleted && to.path !== '/welcome') {
      if (!configStore.config) {
        await configStore.fetchConfig()
      } 

      if(!configStore.config && parseInt(userProfile.UserId) > 318151) {
        return navigateTo('/welcome');
      }
      
    }
  });